import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PassengerDetails from '../../components/PassengerDetails/PassengerDetails';
import { BaseLayout } from '../../components/BaseLayout';
import { Basket } from '../../components/Basket/Basket';
import { PackageBasket } from '../../components/Basket/PackageBasket';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { ProgressBarDefault } from '../../components/ProgressBar/ProgressBarDefaults';
import { ProgressBarPackage } from '../../components/ProgressBar/ProgressBarPackage';

import { LoadingOverlay } from '../../components/LoadingOverlay/LoadingOverlay';

import { accommodation as getAccommodation } from '../../services/acc';
import { isStringEmpty } from '../../services/str';
import { adultCountFromRooms, childCountFromRooms, childCountFromRoomsExInfants, capturePersonEmail, roomsFromPaxString, childAgesFromRooms, adultsFromPaxString } from '../../services/pax';
import { sessionGetSearchParams } from '../../services/search';
import { getBasketDP, getBasketPackage } from '../../services/basket';
import { hwtGraphDate, validateDOB } from '../../services/date';

import { journeyAdd, clearPeople, addLeadPerson, addAdditionalPerson, paymentPayMonthly, productCheckAll, getSessionSelections, } from '../../hooks/useRequest';
import { Person, LeadCustomer, Room, Flight, Transfer, 
  Basket as BasketData, Accommodation, PackageRoom, Package as PackageHoliday, SearchSelectionRoom, Selections, Parking, AirportAccommodation, Lounge  } from '../../hooks/types';
import { addToCart, addToCartPackage } from '../../services/ggle';

import { Button } from '../../components/Button';

import { LoaderSpinner } from '../../components/Loader/jLoader';

import './PassengerDetailsPage.scss';
import { siteNavigate } from '../../services/nav';

const params = queryString.parse(location.search);

export default function PassengerDetailsPage() {
  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [searchParams] = useSearchParams();

  const s = searchParams.get('session');
  const selectedSearchParams = sessionGetSearchParams();

  const [passengersInformation, setPassengersInformation] = useState<any>();
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const [leadPax, setLeadPassenger] = useState<LeadCustomer>();
  const [pax, setPax] = useState<Person[]>([])

  const [sessionId] = useState<string>(s || '');

  const [pricePlan , setPaymentPlan] = useState<any>(null);

  const fNav = useNavigate();

  const [pageLoaded , setPageLoaded] = useState<boolean>(false);

  const [formHasBeenSubmit , setFormSubmit] = useState<boolean>(false);

  const [leadErrors, setLeadErrors] = useState<string[]>([]);

  const [accommodation, setAccommodation] = useState<Accommodation>();

  const [basket, setBasket] = useState<BasketData>();
  const [basketRooms, setBasketRooms] = useState<Room[]>();
  const [basketFlight, setBasketFlight] = useState<Flight>();

  const [basketTransfers, setBasketTransfers] = useState<Transfer | null>(null);
  const [basketParking, setBasketParking] = useState<Parking | null>(null);
  const [basketAirportAcc, setBasketAirpotAcc] = useState<AirportAccommodation | null>(null);
  const [basketLounge, setBasketLounge] = useState<Lounge | null>(null);

  const [basketPackageRooms, setBasketPackageRooms] = useState<PackageRoom[]>();
  const [basketPackage, setBasketPackage] = useState<PackageHoliday>();

  const [selections, setSelections] = useState<Selections>();

  const [selectedRooms, setSelectedRooms] = useState<SearchSelectionRoom[]>([]);
  

  const [smallLoader, setMiniLoad] = useState<boolean>(false);

  type paxError = {
    title: string;
    errors: string[];
  }

  const [paxErrors, setPaxErrors] = useState<paxError[]>([]);

  const loadBasket = async (tripType: number) => {
    if (sessionId) {

      if(tripType == 2 || tripType == 3){

        const basketPack = await getBasketPackage(userToken, sessionId);
        if(basketPack == null){
          return null;
        }

        setBasket(basketPack.basket);
        setBasketPackageRooms(basketPack.rooms);
        setBasketPackage(basketPack.package);

        updatePricePlan(sessionId);

        return basketPack.basket;
      }
      else{
        const basketDp =await getBasketDP(userToken, sessionId);
        if(basketDp == null){
          return null;
        }
        setBasket(basketDp.basket);
        setBasketRooms(basketDp.rooms);
        if(basketDp.flight != null){
          setBasketFlight(basketDp.flight);
        }

        setBasketTransfers(basketDp.transfer);
        setBasketParking(basketDp.parking);
        setBasketAirpotAcc(basketDp.airport_accommodation);
        setBasketLounge(basketDp.lounge);

        updatePricePlan(sessionId);

        return basketDp.basket;
      }

    }
  };


  useEffect(() => {

    async function ini() {


      const selectionsResult = await getSessionSelections(userToken, sessionId);
      const sels = selectionsResult.session.result.selections;

      setSelections(sels);

      const aid = sels.accommodation_id;

      const selectedRooms = roomsFromPaxString(sels.rooms) as SearchSelectionRoom[];

      setSelectedRooms(selectedRooms);

      const adults = adultCountFromRooms(selectedRooms);
      const children = childCountFromRooms(selectedRooms);
      const childAges = childAgesFromRooms(selectedRooms);

      if(aid == null){
        return null;
      }

      const basket = await loadBasket(sels.trip_type);

      let providerID = 4;
      if(basket != null){
        for(const x of basket.products){
          if(x.product_type.id == 0){
            providerID = x.provider_id;
          }
          else if(x.product_type.id == 5){
            providerID = x.provider_id;
          }
        }
      }
      const acc = await getAccommodation(userToken, Number(aid), providerID);
      if(acc != null){
        setAccommodation(acc);
      }

      const jse = sessionStorage.getItem('js3');
      if(jse == null){
        journeyAdd(Number(aid), adults, children, selectedRooms.length, '', 'Passengers', 3, sessionId || '', sels.trip_type, userToken);
        sessionStorage.setItem('js3', '1');
      }

      setPageLoaded(true);
    }

    if (sessionId) {
      ini();
    }

  }, []);

  const onLeadUpdate = (leadCustomer: LeadCustomer) => {
    setLeadPassenger(leadCustomer);
  }

  const onPaxUpdate = (person: Person, index: number) => {
    const np = pax;
    np[index] = person;
    setPax(np);
  }


  const onPaxUpdateAll = (persons: Person[]) => {
    setPax(persons);
  }

  const validatePax = async() => {

    let valid = true;
    const leadErrors: string[] = [];
    const paxErrors: paxError[] = [];


    let pIndex = 0;
    let roomIndex = 0;
    let adultCount = 1;
    let childCount = 1;
    for (const room of selectedRooms) {
      const adults = room.adults;
      const childAges = Object.values(room.childAges);

      for (let i = 0; i < adults; i++) {

         //Do lead pax
        if (i == 0 && roomIndex == 0) {
          if(leadPax == null){
            valid = false;
            leadErrors.push('Title');
            leadErrors.push('First name');
            leadErrors.push('Second name');
            leadErrors.push('Post code');
            leadErrors.push('Address line 1')
            leadErrors.push('Town / City');
            leadErrors.push('County');
            leadErrors.push('Date of Birth');
            leadErrors.push('Contact number');
            leadErrors.push('Email address');
          }   
          else{
            if(isStringEmpty(leadPax.title)){
              valid = false;
              leadErrors.push('Title')
            }
            if(isStringEmpty(leadPax.first_name)){
              valid = false;
              leadErrors.push('First name')
            }
            if(isStringEmpty(leadPax.second_name)){
              valid = false;
              leadErrors.push('Second name')
            }
            if(isStringEmpty(leadPax.post_code)){
              valid = false;
              leadErrors.push('Post code')
            }
            if(isStringEmpty(leadPax.address1)){
              valid = false;
              leadErrors.push('Address line 1')
            }
            if(isStringEmpty(leadPax.address3)){
              valid = false;
              leadErrors.push('Town / City')
            }    
            if(isStringEmpty(leadPax.address4)){
              valid = false;
              leadErrors.push('County')
            }  
            if(isStringEmpty(leadPax.date_of_birth)){
              valid = false;
              leadErrors.push('DOB')
            }
            if(isStringEmpty(leadPax.contact_telephone)){
              valid = false;
              leadErrors.push('Contact number')
            }
            if(isStringEmpty(leadPax.email_address)){
              valid = false;
              leadErrors.push('Email address')
            }
          } 
          adultCount++;
          continue;
        }

        //Do rest of adult pax in this room
        const p = pax[pIndex];
        const pErrors: string[] = [];

        if(p == null){
          valid = false;
          pErrors.push("Title");
          pErrors.push("First name");
          pErrors.push("Second name");
          pErrors.push("DOB");
          paxErrors.push({title: 'Adult ' + adultCount, errors: pErrors});
          adultCount++;
          pIndex++;
          continue;
        }

        if(isStringEmpty(p.title)){
          valid = false;
          pErrors.push("Title");
        }
        if(isStringEmpty(p.first_name)){
          valid = false;
          pErrors.push("First name");
        }
        if(isStringEmpty(p.second_name)){
          valid = false;
          pErrors.push("Second name");
        }
        if(isStringEmpty(p.date_of_birth)){
          valid = false;
          pErrors.push("DOB");
        }

        paxErrors.push({title: 'Adult ' + adultCount, errors: pErrors});
        adultCount++;
        pIndex++;
    }


    for (const x of childAges) {
        const pErrors: string[] = [];
        const c = pax[pIndex];
        if(c == null){
          valid = false;
          pErrors.push("Title");
          pErrors.push("First name");
          pErrors.push("Second name");
          pErrors.push("DOB");
          paxErrors.push({title: 'Child ' + childCount, errors: pErrors});
          childCount++;
          pIndex++;
          continue;
        }

        if(isStringEmpty(c.title)){
          valid = false;
          pErrors.push("Title");
        }
        if(isStringEmpty(c.first_name)){
          valid = false;
          pErrors.push("First name");
        }
        if(isStringEmpty(c.second_name)){
          valid = false;
          pErrors.push("Second name");
        }
        if(isStringEmpty(c.date_of_birth)){
          valid = false;
          pErrors.push("DOB");
        }
        const cDOB = hwtGraphDate(c.date_of_birth);

        const dobVal = await validateDOB(cDOB, x, sessionId, userToken);

        if(!dobVal){
          valid = false;
          pErrors.push("DOB (Age does not match)");
        }

        paxErrors.push({title: 'Child ' + childCount, errors: pErrors});
        childCount++;
        pIndex++;
    }
    roomIndex++;
  }

  setLeadErrors(leadErrors);
  setPaxErrors(paxErrors);
  return valid;
}


const updatePricePlan  = (sessionId: any) => {
  paymentPayMonthly(userToken, 1, sessionId).then((pp) => {
    if(pp == null){
      return null;
    }
    if(pp.basket_payment_plan == null){
      return null;
    }
    if(pp.basket_payment_plan.result == null){
      return null;
    }
    setPaymentPlan(pp.basket_payment_plan.result)
  });
}

  const nextStep = async() => {

    setFormSubmit(true);
    const val = await validatePax();
    
    if(val){
      setMiniLoad(true);    
      if(leadPax == null){
        return
      }

      //Clear out pax
      await clearPeople(userToken, sessionId);

      let pIndex = 0;
      let roomIndex = 0;
      for (const room of selectedRooms) {
        const adults = room.adults;
        const childAges = Object.values(room.childAges);
  
        for (let i = 0; i < adults; i++) {
            //Add Lead 
            if (i == 0 && roomIndex == 0) {

              const leadDOB = hwtGraphDate(leadPax.date_of_birth);
              await addLeadPerson(userToken,sessionId, leadPax.title, leadPax.first_name, leadPax.second_name, leadDOB, 
                leadPax.address1, leadPax.address2, leadPax.address3, leadPax.address4, 
                leadPax.post_code, leadPax.contact_telephone, leadPax.email_address, leadPax.special_requests, 0, leadPax.marketing_permission);

              continue;
            }
    
            //Do rest of adult pax in this room
            const p = pax[pIndex];
            const pDOB = hwtGraphDate(p.date_of_birth);
            await addAdditionalPerson(userToken, sessionId, p.title, p.first_name, p.second_name, pDOB);
            pIndex++;
        }
  
  
        for (const x of childAges) {
          const c = pax[pIndex];
          const cDOB = hwtGraphDate(c.date_of_birth);
          await addAdditionalPerson(userToken, sessionId, c.title, c.first_name, c.second_name, cDOB);
          pIndex++;
        }

        roomIndex++;
      }

      
      if(leadPax != null){
        capturePersonEmail(leadPax, sessionId, userToken);
      }

      if(basket != null){
        if(selections?.trip_type == 2){
          {
            if(basketPackage != null){
              addToCartPackage(basket, basketPackage, configuration);
            }
  
          }
        }
        else{
          if(accommodation != null){

            const adults = adultCountFromRooms(selectedRooms);
            const childrenExInfs = childCountFromRoomsExInfants(selectedRooms);

            addToCart(basket, accommodation, adults, childrenExInfs, configuration, basketRooms, basketFlight, basketTransfers);
          }
          
        }
      }

      await productCheckAll(userToken, sessionId)
      
      siteNavigate(configuration,`/payment${location.search}`, fNav);
    }
    else{
      setMiniLoad(false); 
    }
  }


  let progBar = null;
  let selectRooms = [];
  if(selections?.trip_type == 2){

    ProgressBarPackage[0].isCompleted = true;
    ProgressBarPackage[1].isActive = true;

    progBar = ProgressBarPackage;
    if(params != null){
      if(params.selectedRooms != null){
        const rms = Object.values<any>(params.selectedRooms);
        selectRooms = rms;
      }
    }
  }
  else{
    ProgressBarDefault.forEach((progress) => {
      progress.isActive = false;
      progress.isCompleted = false;
    });
  
    ProgressBarDefault[0].isCompleted = true;
    ProgressBarDefault[1].isCompleted = true;
    ProgressBarDefault[2].isActive = true;

    progBar = ProgressBarDefault;

    if(basketRooms != null){
      selectRooms = basketRooms;
    }
  }


  let secPaxErrors = null;
  if(leadErrors.length > 0 || paxErrors.length > 0){

    let secLeadErrors = null;
    const ulLeadErr = [];
    for(const e of leadErrors){
      ulLeadErr.push(<li>{e}</li>)
    }
    if(ulLeadErr.length > 0){
      secLeadErrors = <div>
      <p>
        Lead passenger errors:
      </p>
      <ul>
        {ulLeadErr}
      </ul>
      </div>
    }

    let secPErrors = null;
    const uPaxErrors = [];
    for(const e of paxErrors){
      const uPaxError = [];

      for(const er of e.errors){
        uPaxError.push(<li>{er}</li>)
      }

      if(e.errors.length == 0){
        continue
      }
      uPaxErrors.push(<><p>
        {e.title} errors:
      </p>
      <ul>
        {uPaxError}
      </ul></>)
    }

    if(uPaxErrors.length > 0){
      secPErrors = <div>
        {uPaxErrors}
      </div>
    }

    if(secLeadErrors != null || secPErrors != null){
      secPaxErrors = <div className='validation-error-wrapper'>
      {secLeadErrors}
      {secPErrors}
      </div>
    }
  }

  if(!pageLoaded || accommodation == null || basket == null){
    return (
      <BaseLayout
        progressBar={progBar}
        isNavigationVisible={false}
        userToken={userToken}
        userConfiguration={configuration}>

  
          <div       style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '2rem',
        height: '600px'
      }}>
   {LoaderSpinner()}
          </div>
         

        
        
        </BaseLayout>)
  }
  


  let bask = null;
  if(selections?.trip_type == 2){

    if(basketPackage == null){
      return null;
    }
    if(basketPackageRooms == null){
      return null;
    }

    bask = <PackageBasket basketData={basket}  pricePlan={pricePlan} selectedPackage={basketPackage} selectedRooms={basketPackageRooms} sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={selectedRooms} >
    <div className='passengers-details-submit-button-desktop'>
    <Button
      label={'Payment'}
      primary={true}
      isTransparent={false}
      onClick={() => {
        nextStep()
      }}
      backgroundColor={configuration.button_colour}
      borderColor={configuration.button_colour}
    />
    </div>
</PackageBasket>
  }
  else{
    bask = <Basket basketData={basket}  pricePlan={pricePlan} selectedFlight={basketFlight || null} selectedRooms={basketRooms || []} selectedTransfer={basketTransfers} selectedParking={basketParking} selectedAirportAccommodation={basketAirportAcc} selectedLounge={basketLounge}  sessionId={sessionId} selectedAccommodation={accommodation} searchedRooms={selectedRooms} >
    <div className='passengers-details-submit-button-desktop'>
    <Button
      label={'Payment'}
      primary={true}
      isTransparent={false}
      onClick={() => {
        nextStep()
      }}
      backgroundColor={configuration.button_colour}
      borderColor={configuration.button_colour}
    />
    </div>
</Basket>
  }




  let xOverlayLoad = null;
  if (smallLoader) {
    xOverlayLoad = <LoadingOverlay ringColour={configuration.button_colour} buttonText={configuration.button_text_colour}></LoadingOverlay>;
  }


  return (
    <BaseLayout
      progressBar={progBar}
      isNavigationVisible={false}
      userToken={userToken}
      userConfiguration={configuration}
    >


      {secPaxErrors}

      {xOverlayLoad}

      <div className='passengers-details-wrapper'>

        <PassengerDetails
          passengersData={passengersInformation}
          selectedRooms={selectRooms}
          searchedRooms={selectedRooms}
          sessionId={sessionId || ''}
          isFormValid={isFormValid}
          onLeadUpdate={(l) => onLeadUpdate(l)}
          onPaxUpdate={(p, i) => onPaxUpdate(p, i)}
          onPaxUpdateAll={(pl) => onPaxUpdateAll(pl)}
          formHasBeenSubmit={formHasBeenSubmit}
        />
        <div className='passengers-details-submit-button-mobile'>


            <Button
                label={'Payment'}
                primary={true}
                isTransparent={false}
                onClick={() => {
                  nextStep()
                }}
                backgroundColor={configuration.button_colour}
                borderColor={configuration.button_colour}
              />

        </div>
        <div className='extras-page-col extras-page-col-basket'>
          {bask}
        </div>
      </div>
    </BaseLayout>
  );
}
